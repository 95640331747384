import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface CreateCompanyBackOfficeVariables {
  name: string;
}

export interface CreateCompanyBackOfficeData {
  createCompany: {
    __typename: 'Company';
    id: string;
  };
}

export default function useMutationCreateCompanyBackOffice() {
  const [createCompany, { data, loading, error }] = useMutation<
    CreateCompanyBackOfficeData,
    CreateCompanyBackOfficeVariables
  >(gql`
    mutation MutationCreateCompanyBackOffice($name: String!) {
      createCompany(name: $name) {
        id
      }
    }
  `);

  return useMemo(
    () => ({
      createCompany,
      result: data?.createCompany,
      isLoading: loading,
      error,
    }),
    [createCompany, data?.createCompany, error, loading],
  );
}
