import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import { useReactiveVar } from '@apollo/client';
import { Button } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { newAlertVar, NEW_ALERT_DEFAULT_VALUE } from '~/apollo/reactiveVariables/newAlertVar';
import Link from '~/components/Link';
import Text from '~/components/Text';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import useUserInteractions from '~/store/useUserInteractions';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import type { AlarmWithCarrier } from '~/types/alarm';
import curateUrl from '~/utils/parse/curateUrl';
import { playAlarm, stopAlarm, unmuteAlarm } from '~/utils/sounds';

import AlertsBottomPopupTableRow from './components/AlertsBottomPopupTableRow';
import MuteButton from './components/MuteButton';

const ContainerDiv = styled.div`
  width: 100%;
  border-top: 2px solid ${theme.colors.red};
  bottom: 0;
  z-index: ${theme.layers.alertsBottomPopup};
  display: grid;
  position: fixed;
  background: ${theme.colors.white};
  grid-template-columns: minmax(0, 240px) minmax(0, 1fr);

  ${theme.medias.extraSmall} {
    grid-template-columns: minmax(0, 120px) minmax(0, 1fr);
  }
`;

const LeftColumnDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  align-items: center;
  background-color: ${theme.colors.red};
  padding-left: 8px;
  padding-right: 24px;

  ${theme.medias.extraSmall} {
    grid-template-columns: minmax(0, 1fr);
    padding: 0;
  }
`;

const LeftColumnContentDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${theme.medias.extraSmall} {
    display: none;
  }
`;

const AlertIconImg = styled.img`
  height: 26px;
  margin-bottom: 8px;
`;

const AlertCountSpan = styled.span`
  color: ${theme.colors.white};
  font-weight: bold;
  margin-bottom: 2px;
`;

const ViewAgentsStyledLink = styled(Link)`
  color: ${theme.colors.white};
  text-align: center;

  &:hover {
    text-decoration: underline;
    color: ${theme.colors.white};
  }
`;

const RightColumnDiv = styled.div`
  padding: 24px 48px;
  min-height: 124px;
  max-height: 50vh;
  overflow-y: auto;

  ${theme.medias.extraSmall} {
    padding: 24px 16px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
`;

const ExpandCollapseButton = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0px;
  left: calc(50% + 120px);
  transform: translateX(-50%);
`;

function getSortedAlarmAgents(agents: Agent[], ongoingAlarms: AlarmWithCarrier[]): Agent[] {
  return orderBy(
    agents,
    (agent) => {
      const agentOngoingAlarms = ongoingAlarms.filter((alarm) => alarm.carrier.id === agent.id);
      if (agentOngoingAlarms.every((alert) => alert.administrator)) {
        return 0;
      }
      if (agentOngoingAlarms.every((alert) => !alert.administrator)) {
        return 2;
      }
      return 1;
    },
    'desc',
  );
}
const AlertsBottomPopup = memo(() => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { carrierId } = useReactiveVar(newAlertVar);
  const userInteractedWithDocument = useUserInteractions(
    (state) => state.userInteractedWithDocument,
  );
  const { agentsWithOngoingAlarms } = useAgentsContext();
  const { ongoingAlarms, hasAlert } = useAlarmsContext();

  useEffect(
    () => () => {
      stopAlarm();
    },
    [],
  );

  useEffect(() => {
    if (!carrierId) {
      return;
    }

    setCollapsed(false);
    unmuteAlarm();
    // alert has been shown and it is not "new" anymore so we clear the state
    newAlertVar(NEW_ALERT_DEFAULT_VALUE);
  }, [carrierId]);

  // TODO: move this to something global, this is not this component's concern
  useEffect(() => (hasAlert ? playAlarm() : stopAlarm()), [hasAlert, userInteractedWithDocument]);

  if (!hasAlert || agentsWithOngoingAlarms.length === 0) {
    return null;
  }

  return (
    <ContainerDiv
      data-id={`${collapsed ? 'collapsed' : 'expanded'}-alert-bottom-popup`}
      style={{
        height: collapsed ? `${theme.dimensions.alertsBottomPopupCollapsedHeight}px` : 'auto',
      }}
    >
      <LeftColumnDiv>
        <MuteButton collapsed={collapsed} />
        <LeftColumnContentDiv>
          {!collapsed && (
            <AlertIconImg
              src={curateUrl(`/icons/alert/alert-${collapsed ? 'small' : 'large'}-white.png`)}
              alt="Alert"
            />
          )}
          <AlertCountSpan
            data-id="alert-count"
            style={{
              fontSize: collapsed ? '14px' : '20px',
            }}
          >
            {i18n.t('alertsBottomPopup.alertCount', { count: agentsWithOngoingAlarms.length })}
          </AlertCountSpan>
          <ViewAgentsStyledLink
            to={routes.agents()}
            data-id="view-all-agents-link"
            style={{
              fontSize: collapsed ? '14px' : '18px',
            }}
          >
            {i18n.t('alertsBottomPopup.viewAllAgents')}
          </ViewAgentsStyledLink>
        </LeftColumnContentDiv>
      </LeftColumnDiv>
      {!collapsed && (
        <RightColumnDiv>
          <StyledTable>
            <tbody>
              {getSortedAlarmAgents(agentsWithOngoingAlarms, ongoingAlarms).map((agent) => (
                <AlertsBottomPopupTableRow
                  key={agent.id}
                  agent={agent}
                  collapse={() => setCollapsed(!collapsed)}
                />
              ))}
            </tbody>
          </StyledTable>
        </RightColumnDiv>
      )}
      <ExpandCollapseButton
        onClick={() => setCollapsed(!collapsed)}
        data-id={`alert-bottom-popup-${collapsed ? 'expand' : 'collapse'}-btn`}
      >
        {collapsed ? <UpOutlined /> : <DownOutlined />}
        <Text style={{ paddingLeft: '5px' }}>
          {i18n.t(`alertsBottomPopup.${collapsed ? 'expand' : 'collapse'}`)}
        </Text>
      </ExpandCollapseButton>
    </ContainerDiv>
  );
});

AlertsBottomPopup.displayName = 'AlertsBottomPopup';

export default AlertsBottomPopup;
