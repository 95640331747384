import { Switch } from 'antd';
import snakeCase from 'lodash/snakeCase';
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import useMutationSetCompanyAttribute from '~/apollo/hooks/company/useMutationSetCompanyAttribute';
import TabSectionHeader from '~/components/SettingsTabs/components/TabSectionHeader';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  max-width: 840px;

  ${theme.medias.extraSmall} {
    width: 100%;
    max-width: 100%;
    grid-template-columns: minmax(0, 1fr);
  }
`;

const Ul = styled.ul`
  margin: 0 0 22px;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TabCompanyFeatures = memo(() => {
  const { setCompanyAttribute, isLoading } = useMutationSetCompanyAttribute();
  const { currentCompany } = useCompany();
  const { companyFeatures } = useCompanyFeatures();
  const { isSuperAdmin } = useCurrentUserContext();
  const { openModal } = useModalsContext();
  const [selectedFeatures, setSelectedFeatures] = useState<typeof companyFeatures>(companyFeatures);

  useEffect(() => {
    if (companyFeatures) {
      setSelectedFeatures(companyFeatures);
    }
  }, [companyFeatures]);

  if (!currentCompany?.id) {
    return <p>{i18n.t('companyFeatures.missingCompany')}</p>;
  }

  const {
    // Events
    issueTracking,
    alarmsPage,
    alarmsPageOnlySuperAdmins,
    logsPage,
    logsPageOnlySuperAdmins,
    // Location
    locationHistory,
    locationHistoryOnlySuperAdmins,
    // Video
    realtimeLocation,
    playbackRecordedVideos,
    remoteVideoStreamingControl,
    // Sensors
    emergencyButton,
    biometricData,
    heartRateSensor,
    physiologicalTemperatureSensor,
    bodyTemperatureSensor, // deprecated
    gasSensor,
    impactDetectionFront,
    impactDetectionBack,
    // General
    ...remainingFeatures
  } = selectedFeatures;

  type FeatureEntries = [keyof typeof companyFeatures, boolean][];

  const generalFeatures = Object.entries(remainingFeatures) as FeatureEntries;
  const eventsFeatures = Object.entries({
    issueTracking,
    alarmsPage,
    alarmsPageOnlySuperAdmins,
    logsPage,
    logsPageOnlySuperAdmins,
  }) as FeatureEntries;
  const locationFeatures = Object.entries({
    realtimeLocation,
    locationHistory,
    locationHistoryOnlySuperAdmins,
  }) as FeatureEntries;
  const videoFeatures = Object.entries({
    playbackRecordedVideos,
    remoteVideoStreamingControl,
  }) as FeatureEntries;
  const sensorsFeatures = Object.entries({
    emergencyButton,
    biometricData,
    heartRateSensor,
    bodyTemperatureSensor, // deprecated
    physiologicalTemperatureSensor,
    gasSensor,
    impactDetectionFront,
    impactDetectionBack,
  }) as FeatureEntries;

  const renderFeatures = (featuresEntries: FeatureEntries) => (
    <Ul>
      {featuresEntries.map(([featureKey, isEnabled]) => (
        <Li key={featureKey} data-id={`company-feature-${featureKey}`}>
          <Switch
            checked={isEnabled}
            disabled={!isSuperAdmin}
            loading={isLoading}
            onChange={(checked) => {
              openModal({
                type: 'confirmation',
                title: null,
                description: (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: i18n.t<string>('companyFeatures.modalDescriptionHtml', {
                        feature: i18n.t(`companyFeatures.${featureKey}`),
                        company: currentCompany?.name,
                        status: checked ? 'ON' : '0FF',
                      }),
                    }}
                  />
                ),
                actionType: checked ? 'primary' : 'danger',
                action: async () => {
                  await setCompanyAttribute({
                    name: `feature_${snakeCase(featureKey)}`,
                    value: checked ? 'enabled' : 'disabled',
                  });
                },
              });
            }}
          />
          <span style={{ opacity: isEnabled ? 1 : 0.5 }}>
            {i18n.t(`companyFeatures.${featureKey}`)}
          </span>
        </Li>
      ))}
    </Ul>
  );

  return (
    <>
      <div>
        <b>{i18n.t('companyFeatures.selectedCompany')}:</b> {currentCompany?.name}
      </div>
      <GridDiv>
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.general')} />
          {renderFeatures(generalFeatures)}
          <TabSectionHeader title={i18n.t('companyFeatures.events')} />
          {renderFeatures(eventsFeatures)}
          <TabSectionHeader title={i18n.t('companyFeatures.location')} />
          {renderFeatures(locationFeatures)}
        </div>
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.sensors')} />
          {renderFeatures(sensorsFeatures)}
          <TabSectionHeader title={i18n.t('companyFeatures.video')} />
          {renderFeatures(videoFeatures)}
        </div>
      </GridDiv>
    </>
  );
});

TabCompanyFeatures.displayName = 'TabCompanyFeatures';

export default TabCompanyFeatures;
