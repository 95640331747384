import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import BookOutlined from '@ant-design/icons/BookOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloudOutlined from '@ant-design/icons/CloudOutlined';
import CustomerServiceOutlined from '@ant-design/icons/CustomerServiceOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import MenuOutlined from '@ant-design/icons/MenuOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { Badge, Dropdown, type MenuProps } from 'antd';
import type { MenuDividerType } from 'antd/lib/menu/hooks/useItems';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import usePathname from '~/hooks/usePathname';
import i18n from '~/locales/i18n';
import useHighlightedFeatures, { getHighlightedItems } from '~/store/useHighlightedFeatures';
import theme from '~/theme';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import getUserName from '~/utils/user/getUserName';

const WrapperDiv = styled.div`
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserButton = styled.button`
  color: ${theme.colors.white};
  font-weight: 600;
  font-size: 14px;
  padding: 6px 0;
  margin: 0;
  border: none;
  outline: none;
  background: transparent;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${theme.colors.grey};
  }
`;

const NavbarUserDropdown = memo(() => {
  const pathname = usePathname();
  const { getUser, logout } = useAuthenticationContext();
  const { currentUser, isSuperAdmin } = useCurrentUserContext();
  const { companyFeatures, showLogsPageFeature } = useCompanyFeatures();
  const { openModal } = useModalsContext();

  const acknowledgedHighlightedFeatures = useHighlightedFeatures(
    (state) => state.acknowledgedHighlightedFeatures,
  );

  const activeHighlightIds = useMemo(
    () =>
      getHighlightedItems({ companyFeatures, isSuperAdmin })
        .filter((item) => item.active)
        .map((item) => item.id),
    [companyFeatures, isSuperAdmin],
  );

  const highlightedFeaturesCount =
    activeHighlightIds.length -
    acknowledgedHighlightedFeatures.filter((item) => activeHighlightIds.includes(item)).length;

  const menu: MenuProps = useMemo(
    () => ({
      activeKey: pathname,
      onClick: async ({ key }) => {
        switch (key) {
          case 'highlightedFeatures':
            openModal({ type: 'highlightedFeatures' });
            break;
          case 'about':
            openModal({ type: 'about' });
            break;
          case 'logout':
            logout()
              .then(() => {
                notification.warning({
                  message: i18n.t('general.auth.loggedOutTitle'),
                  description: i18n.t('general.auth.loggedOutDescription'),
                });
              })
              .catch(() => {
                notification.error({ message: i18n.t('general.auth.logoutFailTitle') });
                logger.error('NavbarUserDropdown: menu logout error');
              });
            break;
          default:
            break;
        }
      },
      theme: 'light',
      items: [
        {
          key: routes.settings().pathname,
          label: (
            <Link to={routes.settings()} data-id="settings-link">
              {i18n.t('header.user.settings')}
            </Link>
          ),
          icon: <MenuOutlined />,
        },
        ...(companyFeatures.dataAnalysisReports
          ? [
              {
                key: routes.reports().pathname,
                label: (
                  <Link to={routes.reports()} data-id="reports-link">
                    {i18n.t('header.user.reports')}
                  </Link>
                ),
                icon: <BarChartOutlined />,
              },
            ]
          : []),
        ...(showLogsPageFeature
          ? [
              {
                key: routes.logs().pathname,
                label: (
                  <Link to={routes.logs()} data-id="logs-link">
                    {i18n.t('header.user.logs')}
                  </Link>
                ),
                icon: <ClockCircleOutlined />,
              },
            ]
          : []),
        {
          key: routes.userManual().pathname,
          label: (
            <Link to={routes.userManual()} data-id="user-manual-link">
              {i18n.t('header.user.userManual')}
            </Link>
          ),
          icon: <BookOutlined />,
        },
        {
          key: routes.support().pathname,
          label: (
            <Link to={routes.support()} data-id="user-support-link">
              {i18n.t('header.user.support')}
            </Link>
          ),
          icon: <CustomerServiceOutlined />,
        },
        {
          key: 'highlightedFeatures',
          label: (
            <div style={{ marginRight: '20px' }} data-id="highlighted-features-btn">
              <Badge
                count={highlightedFeaturesCount}
                offset={[14, 7]}
                style={{ boxShadow: 'none' }}
                size="small"
              >
                {i18n.t('header.user.highlightedFeatures')}
              </Badge>
            </div>
          ),
          icon: <NotificationOutlined />,
        },
        {
          key: 'about',
          label: <div data-id="about-btn">{i18n.t('header.user.about')}</div>,
          icon: <InfoCircleOutlined />,
        },
        ...(isSuperAdmin
          ? [
              {
                type: 'divider' as MenuDividerType['type'],
              },
              {
                key: routes.backoffice().pathname,
                label: (
                  <Link to={routes.backoffice()} data-id="backoffice-link">
                    {i18n.t('header.user.backoffice')}
                  </Link>
                ),
                icon: <CloudOutlined />,
              },
            ]
          : []),
        {
          type: 'divider' as MenuDividerType['type'],
        },
        {
          key: 'logout',
          label: <div data-id="logout-btn">{i18n.t('header.user.logoutButton')}</div>,
          icon: <LogoutOutlined />,
        },
      ],
    }),
    [
      isSuperAdmin,
      pathname,
      showLogsPageFeature,
      highlightedFeaturesCount,
      companyFeatures.dataAnalysisReports,
      openModal,
      logout,
    ],
  );

  return (
    <WrapperDiv>
      <Dropdown trigger={['click']} menu={menu}>
        <UserButton>
          <UserOutlined />
          <span style={{ display: 'inline-block', marginLeft: '8px' }} data-id="username">
            {getUserName(currentUser) ?? getUser()?.getUsername()}
          </span>
        </UserButton>
      </Dropdown>
    </WrapperDiv>
  );
});

NavbarUserDropdown.displayName = 'NavbarUserDropdown';

export default NavbarUserDropdown;
