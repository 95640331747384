import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Form, Input, Modal } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import useMutationCreateCompanyBackOffice from '~/apollo/hooks/backoffice/useMutationCreateCompanyBackOffice';
import useMutationUpdateCompanyBackOffice from '~/apollo/hooks/backoffice/useMutationUpdateCompanyBackOffice';
import useQueryCompanyListBackOffice from '~/apollo/hooks/backoffice/useQueryCompanyListBackOffice';
import type { ModalProps } from '~/types/modal';
import notification from '~/utils/notification';

const BottomDiv = styled.div`
  margin-top: 16px;
  opacity: 0.5;
`;

interface FormValues {
  name: string;
}

export interface BackOfficeCreateEditCompanyModalProps {
  companyId: string | undefined;
}

const BackOfficeCreateEditCompanyModal = memo(
  ({ isOpen, onClose, companyId }: ModalProps & BackOfficeCreateEditCompanyModalProps) => {
    const { createCompany, isLoading: isCreating } = useMutationCreateCompanyBackOffice();
    const { updateCompany, isLoading: isUpdating } = useMutationUpdateCompanyBackOffice();
    const {
      companyList,
      isLoading: isCompanyListLoading,
      refetchCompanyList,
    } = useQueryCompanyListBackOffice();

    const isLoading = isCreating || isUpdating || isCompanyListLoading;

    const [form] = Form.useForm<FormValues>();

    // TODO: There is still no backend implementation for the edit, that is why we show disabled
    const isEdit = !!companyId;

    const maybeCompany = companyList.find((company) => company.id === companyId);

    const handleSubmit = async ({ name }: FormValues) => {
      try {
        if (isEdit) {
          await updateCompany({
            variables: {
              company_id: companyId,
              name: name.trim(),
            },
          });
        } else {
          await createCompany({
            variables: {
              name: name.trim(),
            },
          });
        }
        await refetchCompanyList();
        onClose();
        notification.success({
          message: 'Success',
          description: `Company ${isEdit ? 'updated' : 'created'} successfully!`,
        });
      } catch (error) {
        notification.error({
          message: (error as any)?.message || 'Error',
        });
      }
    };

    return (
      <Modal
        title={isEdit ? 'Edit company' : 'Create company'}
        footer={null}
        centered
        width={500}
        open={isOpen}
        onCancel={onClose}
      >
        <Form
          form={form}
          layout="vertical"
          validateTrigger="onBlur"
          initialValues={undefined}
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            label="Company name"
            rules={[{ required: true, message: 'A company must have a name' }]}
          >
            <Input
              placeholder="Name of the company"
              disabled={isLoading || isEdit}
              defaultValue={maybeCompany?.name}
            />
          </Form.Item>
          <Button
            size="middle"
            type="primary"
            htmlType="submit"
            disabled={isLoading || isEdit}
            loading={isLoading}
            icon={<SaveOutlined />}
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </Form>
        {isEdit && (
          <BottomDiv>
            <b>Company ID:</b> {companyId}
          </BottomDiv>
        )}
      </Modal>
    );
  },
);

BackOfficeCreateEditCompanyModal.displayName = 'BackOfficeCreateEditCompanyModal';

export default BackOfficeCreateEditCompanyModal;
