import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface UpdateCompanyBackOfficeVariables {
  company_id: string;
  name: string;
}

export interface UpdateCompanyBackOfficeData {
  updateCompany: {
    __typename: 'Company';
    id: string;
  };
}

export default function useMutationUpdateCompanyBackOffice() {
  // TODO: This GraphQL query is still not implemented on the backend!
  const [updateCompany, { data, loading, error }] = useMutation<
    UpdateCompanyBackOfficeData,
    UpdateCompanyBackOfficeVariables
  >(gql`
    mutation MutationUpdateCompanyBackOffice($company_id: ID!, $name: String!) {
      updateCompany(company_id: $company_id, name: $name) {
        id
      }
    }
  `);

  return useMemo(
    () => ({
      updateCompany,
      result: data?.updateCompany,
      isLoading: loading,
      error,
    }),
    [updateCompany, data?.updateCompany, error, loading],
  );
}
