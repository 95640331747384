import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface DeleteCompanyBackOfficeVariables {
  company_id: string;
}

export interface DeleteCompanyBackOfficeData {
  deleteCompany: {
    __typename: 'Company';
    id: string;
  };
}

export default function useMutationDeleteCompanyBackOffice() {
  const [deleteCompany, { data, loading, error }] = useMutation<
    DeleteCompanyBackOfficeData,
    DeleteCompanyBackOfficeVariables
  >(gql`
    mutation MutationDeleteCompanyBackOffice($company_id: ID!) {
      deleteCompany(company_id: $company_id)
    }
  `);

  return useMemo(
    () => ({
      deleteCompany,
      result: data?.deleteCompany,
      isLoading: loading,
      error,
    }),
    [deleteCompany, data?.deleteCompany, error, loading],
  );
}
