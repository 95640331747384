type Params = Record<string, string | undefined>;

function computeSearchParams(params: Params | undefined): string | undefined {
  if (!params) {
    return undefined;
  }
  const searchParamsQuery = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => value && searchParamsQuery.set(key, value));
  return `?${searchParamsQuery}`;
}

const routes = {
  // Index Route
  index: (params?: Params) => ({
    pathname: '/',
    search: computeSearchParams(params),
  }),
  // Private Routes
  map: (params?: Params) => ({
    pathname: '/map',
    search: computeSearchParams(params),
  }),
  /** @deprecated Use routes.map instead */
  dashboard: (params?: Params) => ({
    pathname: '/dashboard',
    search: computeSearchParams(params),
  }),
  agents: (params?: Params) => ({
    pathname: '/agents',
    search: computeSearchParams(params),
  }),
  alarms: (params?: Params) => ({
    pathname: '/alarms',
    search: computeSearchParams(params),
  }),
  logs: (params?: Params) => ({
    pathname: '/logs',
    search: computeSearchParams(params),
  }),
  settings: (params?: Params) => ({
    pathname: '/settings',
    search: computeSearchParams(params),
  }),
  backoffice: (params?: Params) => ({
    pathname: '/backoffice',
    search: computeSearchParams(params),
  }),
  userManual: (params?: Params) => ({
    pathname: '/user-manual',
    search: computeSearchParams(params),
  }),
  developerTools: (params?: Params) => ({
    pathname: '/developer-tools',
    search: computeSearchParams(params),
  }),
  support: (params?: Params) => ({
    pathname: '/support',
    search: computeSearchParams(params),
  }),
  status: (params?: Params) => ({
    pathname: '/status',
    search: computeSearchParams(params),
  }),
  reports: (params?: Params) => ({
    pathname: '/reports',
    search: computeSearchParams(params),
  }),
  // Public Routes
  login: (params?: Params) => ({
    pathname: '/login',
    search: computeSearchParams(params),
  }),
  newPasswordChallenge: (params?: Params) => ({
    pathname: '/new-password-challenge',
    search: computeSearchParams(params),
  }),
  resetPassword: (params?: Params) => ({
    pathname: '/reset-password',
    search: computeSearchParams(params),
  }),
  verifyConfirmationCode: (params?: Params) => ({
    pathname: '/verify-confirmation-code',
    search: computeSearchParams(params),
  }),
  forgotPassword: (params?: Params) => ({
    pathname: '/forgot-password',
    search: computeSearchParams(params),
  }),
  mfa: (params?: Params) => ({
    pathname: '/mfa',
    search: computeSearchParams(params),
  }),
} satisfies Record<
  string,
  (params?: Params) => {
    pathname: string;
    search: string | undefined;
  }
>;

export default routes;
