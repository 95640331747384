import ControlOutlined from '@ant-design/icons/ControlOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import PageSideTabs from '~/components/PageSideTabs';
import routes from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';
import theme from '~/theme';

import TabCompanies from './components/TabCompanies';
import TabCompanyFeatures from './components/TabCompanyFeatures';
import TabGlobalConfigurations from './components/TabGlobalConfigurations';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 300px) minmax(0, 1fr);
  grid-gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const StickyDiv = styled.div`
  position: sticky;
  top: calc(${theme.dimensions.navbarHeight}px + 16px);

  ${theme.medias.lteSmall} {
    position: initial;
    top: 0;
  }
`;

const TabTitleH3 = styled.h3`
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 8px;
`;

const TabContentDiv = styled.div`
  padding-bottom: 64px;
`;

enum TabEnum {
  companies = 'companies',
  companyFeatures = 'companyFeatures',
  globalConfigurations = 'globalConfigurations',
}

const BackOfficePage = memo(() => {
  const { isSuperAdmin } = useCurrentUserContext();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [tab, setTab] = useState<TabEnum>((queryParams.tab as TabEnum) || TabEnum.companies);

  const tabs = useMemo(
    () => [
      { key: TabEnum.companies, icon: <ShopOutlined /> },
      { key: TabEnum.companyFeatures, icon: <ControlOutlined /> },
      { key: TabEnum.globalConfigurations, icon: <DatabaseOutlined /> },
    ],
    [],
  );

  useEffect(() => {
    if (queryParams.tab) {
      setTab(queryParams.tab as TabEnum);
    }
  }, [queryParams.tab]);

  const handleTabSelect = useCallback(
    (selectedTab: TabEnum) => {
      setTab(selectedTab);
      navigate(routes.backoffice({ ...queryParams, tab: selectedTab }));
    },
    [navigate, queryParams],
  );

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <PageContentWrapper>
      <Container>
        <PageHeader
          title={i18n.t('backofficePage.title')}
          subtitle={i18n.t('backofficePage.subtitle')}
        />
        <GridDiv>
          <aside>
            <StickyDiv>
              <PageSideTabs
                activeItemKey={tab}
                items={tabs.map(({ key, icon }) => ({
                  key,
                  dataId: `backoffice-${key}-link`,
                  icon,
                  label: i18n.t(`backofficePage.tabs.${key}`),
                  onClick: () => handleTabSelect(key),
                }))}
              />
            </StickyDiv>
          </aside>
          <TabContentDiv>
            <TabTitleH3 data-id="backoffice-page-tab-title">
              {i18n.t(`backofficePage.tabs.${tab}`)}
            </TabTitleH3>
            {tab === TabEnum.companies && <TabCompanies />}
            {tab === TabEnum.companyFeatures && <TabCompanyFeatures />}
            {tab === TabEnum.globalConfigurations && <TabGlobalConfigurations />}
          </TabContentDiv>
        </GridDiv>
      </Container>
    </PageContentWrapper>
  );
});

BackOfficePage.displayName = 'BackOfficePage';

export default BackOfficePage;
