import { Tooltip } from 'antd';
import { memo, useMemo, type CSSProperties } from 'react';
import { TbActivity } from 'react-icons/tb';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import { EQUIPMENT_STATUS } from '~/types/equipment';
import getYesOrNo from '~/utils/parse/getYesOrNo';

import getHasPopupSectionError from '../utils/getHasPopupSectionError';

const TooltipUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const SyledIcon = styled(TbActivity)<{ $isWorking: boolean }>`
  font-size: 16px;
  transform: translate(-1px, 2px);
  color: ${(props) => (props.$isWorking ? theme.colors.green : 'rgba(0, 0, 0, 0.4)')};
  margin-right: 3px;
`;

const TitleH4 = styled.h4`
  display: inline-block;
  color: ${theme.colors.darkBlue};
  margin: 12px 0 2px;
  cursor: help;
`;

interface Props {
  className?: string;
  style?: CSSProperties;
  title: string;
  status: EQUIPMENT_STATUS | undefined;
  showConnected: boolean;
  agent: Agent | undefined;
}

const PopupSectionTitle = memo(
  ({ className, style, title, status, showConnected, agent }: Props) => {
    const statusText = useMemo(() => {
      if (agent?.isOffline) {
        return i18n.t('brainStatus.offline');
      }
      if (agent?.connectionLost) {
        return i18n.t('brainStatus.connectionLost');
      }
      if (!status) {
        return undefined;
      }
      return status === EQUIPMENT_STATUS.no_error
        ? 'OK'
        : i18n.t<string>(`general.equipment.status.${status}`);
    }, [agent?.connectionLost, agent?.isOffline, status]);

    const hasError = getHasPopupSectionError({ agent, status });

    return (
      <Tooltip
        title={
          <TooltipUl>
            {statusText && (
              <li>
                <b>{i18n.t('brainBatteryLteSignal.status')}:</b> {statusText}
              </li>
            )}
            {showConnected && (
              <li>
                <b>{i18n.t('brainBatteryLteSignal.connected')}:</b> {getYesOrNo(agent?.isConnected)}
              </li>
            )}
          </TooltipUl>
        }
        placement="right"
      >
        <TitleH4 className={className} style={style}>
          <SyledIcon $isWorking={!hasError} />
          {title}
        </TitleH4>
      </Tooltip>
    );
  },
);

PopupSectionTitle.displayName = 'PopupSectionTitle';

export default PopupSectionTitle;
