import type { Agent } from '~/types/agent';
import { EQUIPMENT_STATUS } from '~/types/equipment';

export default function getHasPopupSectionError({
  agent,
  status,
}: {
  agent: Agent | undefined;
  status: EQUIPMENT_STATUS | undefined;
}): boolean {
  const hasError =
    !agent ||
    agent.isOffline ||
    agent.connectionLost ||
    (!!status && status !== EQUIPMENT_STATUS.no_error);

  return hasError;
}
