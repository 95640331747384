import { Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';
import curateUrl from '~/utils/parse/curateUrl';

const BadgeDiv = styled.div`
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

const AlarmIconImg = styled.img`
  height: 14px;
  transform: translateY(-1px);
`;

const H4 = styled.h4`
  margin: 0;
  font-size: 14px;
  line-height: 1;
  color: ${theme.colors.white};
`;

const TooltipH5 = styled.h5`
  margin: 0 0 8px;
  font-size: 14px;
`;

const TooltipUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
`;

interface Props {
  status: AGENT_STATUS;
  isOffline: boolean;
  vehicle: Vehicle | undefined;
}

const StatusBadge = memo(({ status, isOffline, vehicle }: Props) => {
  const hasAlarm = status === AGENT_STATUS.alert;

  const backgroundColor = getAgentStatusColor({ status, isOffline });

  const hasTooltip = vehicle && vehicle?.agents?.length > 0;

  const content = (
    <BadgeDiv style={{ backgroundColor, cursor: hasTooltip ? 'help' : 'default' }}>
      {hasAlarm && <AlarmIconImg src={curateUrl('/icons/alert/alert-small-white.png')} alt="" />}
      <H4 data-id="status-badge">
        {i18n.t(
          `general.generalAlarms.${isOffline && status !== AGENT_STATUS.alert ? 'offline' : status}`,
        )}
      </H4>
    </BadgeDiv>
  );

  if (hasTooltip) {
    const sortedAgents = sortAgentsByStatusAndName(vehicle.agents);

    return (
      <Tooltip
        placement="right"
        title={
          <>
            <TooltipH5>{`${i18n.t('agentsPage.vehicle')} (${vehicle.plateNumber})`}</TooltipH5>
            <TooltipUl>
              {sortedAgents.map((agent) => (
                <li key={agent.id}>
                  <b>{agent.name}:</b> {i18n.t(`general.generalAlarms.${agent.status}`)}
                </li>
              ))}
            </TooltipUl>
          </>
        }
      >
        {content}
      </Tooltip>
    );
  }

  return content;
});

StatusBadge.displayName = 'StatusBadge';

export default StatusBadge;
