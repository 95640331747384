import type { EquipmentStatusMap } from '~/types/equipment';
import type {
  BatterySensorType,
  GasSensorType,
  GpsSensorType,
  LteSignalStrengthSensorType,
  PhysiologicalTemperatureSensorType,
} from '~/types/sensor';
import type { IvsStream, STREAM_REQUESTED_STATUS } from '~/types/videoStream';

export enum AGENT_STATUS {
  alert = 'alert',
  warning = 'warning',
  connectionLost = 'connectionLost',
  inMission = 'inMission',
  inSafeZone = 'inSafeZone',
}

export interface AgentAttributes {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  team?: string;
  plate_number?: string;
  in_safe_zone?: boolean;
  team_leader?: boolean;
  function?: string;
  gender?: string;
  age?: string;
  email?: string;
  acronym: string;
}

export interface Agent {
  id: string;
  name: string;
  completeName: string;
  team: string;
  deviceName?: string;
  attributes: AgentAttributes;
  isConnected: boolean;
  isOffline: boolean;
  sensors: {
    gps?: GpsSensorType | null;
    heartRate?: number | null;
    physiologicalTemperature?: PhysiologicalTemperatureSensorType | null;
    /** @deprecated This is an old sensor and it will eventually be removed */
    bodyTemperature?: number | null; // deprecated
    gas?: GasSensorType | null;
    battery?: BatterySensorType | null;
    lteSignalStrength?: LteSignalStrengthSensorType | null;
  };
  status: AGENT_STATUS;
  equipmentStatus: EquipmentStatusMap;
  missionStartTimeISO: string;
  missionEndTimeISO: string;
  connectionLost: boolean;
  lastUpdate?: string;
  video_stream?: IvsStream;
  requested_video_stream_status?: STREAM_REQUESTED_STATUS;
}
