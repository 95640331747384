import CarFilled from '@ant-design/icons/CarFilled';
import CarOutlined from '@ant-design/icons/CarOutlined';
import FormatPainterOutlined from '@ant-design/icons/FormatPainterOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { Dropdown, MenuProps, Spin, Tooltip } from 'antd';
import difference from 'lodash/difference';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  CARRIER_MAP_ZOOM_LEVEL,
  DEFAULT_MAP_ZOOM_LEVEL_MIN,
  DEFAULT_MAP_ZOOM_LEVEL_MAX,
} from '~/config/defaults';
import mapThemes from '~/config/mapThemes';
import i18n from '~/locales/i18n';
import useMapSettings, { mapThemeKeys } from '~/store/useMapSettings';
import theme from '~/theme';
import { AGENT_STATUS } from '~/types/agent';
import mapFitBounds from '~/utils/map/mapFitBounds';

import useDeepCompareEffectForMaps from './hooks/useDeepCompareEffectForMaps';
import useMarkers, { type MarkerType } from './hooks/useMarkers';
import computeAnchorPoint from './utils/computeAnchorPoint';
import computeIcon from './utils/computeIcon';
import getMarkerClusterRender from './utils/getMarkerClusterRender';

const BUTTON_SIZE = 32;
const GUTTER = 8;

const BlurredDiv = styled.div`
  filter: blur(0.25rem);
  opacity: 0.5;
`;

const MapDiv = styled.div`
  flex-grow: 1;
  height: calc(100vh - ${theme.dimensions.navbarHeight}px);
`;

const LoadingDiv = styled.div`
  position: absolute;
  top: ${theme.dimensions.navbarHeight}px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: ${theme.dimensions.navbarHeight}px;
  z-index: ${theme.layers.loadingSpinner};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: progress;

  & > div {
    width: 168px;
    height: 168px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    box-shadow:
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);

    & > span {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

const ButtonsDiv = styled.div`
  position: fixed;
  top: calc(134px + ${GUTTER}px);
  right: 8px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${GUTTER}px;
  z-index: ${theme.layers.mapTopRightActions};

  ${theme.medias.lteSmall} {
    z-index: ${theme.layers.base};
  }

  & > span {
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-size: 18px !important;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.14902);
    width: ${BUTTON_SIZE}px;
    height: ${BUTTON_SIZE}px;
  }
`;

const agentStatuses = [
  AGENT_STATUS.inSafeZone,
  AGENT_STATUS.inMission,
  AGENT_STATUS.warning,
  AGENT_STATUS.connectionLost,
  AGENT_STATUS.alert,
];

export interface Props {
  isLoading: boolean;
  isBlurred: boolean;
  initialZoom: google.maps.MapOptions['zoom'];
  initialLat: number;
  initialLng: number;
  followingZoom: google.maps.MapOptions['zoom'] | undefined;
  followingLat: number | undefined;
  followingLng: number | undefined;
}

const MainMapElement = memo(
  ({
    isLoading,
    isBlurred,
    initialZoom,
    initialLat,
    initialLng,
    followingZoom,
    followingLat,
    followingLng,
  }: Props) => {
    const markers = useMarkers();
    const markersRef = useRef<{ data: MarkerType; markerElement: google.maps.Marker }[]>([]);
    const markerClusterersRef = useRef<MarkerClusterer[]>([]);
    const infoWindowRef = useRef(new google.maps.InfoWindow());
    const mapDivRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const mapTheme = useMapSettings((state) => state.mapTheme);
    const setMapTheme = useMapSettings((state) => state.setMapTheme);
    const trafficLayerRef = useRef<google.maps.TrafficLayer>(new google.maps.TrafficLayer());
    const showMapTrafficLayer = useMapSettings((state) => state.showMapTrafficLayer);
    const setShowMapTrafficLayer = useMapSettings((state) => state.setShowMapTrafficLayer);

    // Initialize
    useEffect(() => {
      if (!mapDivRef.current || map) {
        return;
      }

      const nextMap = new window.google.maps.Map(mapDivRef.current, {
        mapTypeId: mapThemes[mapTheme].type,
        styles: mapThemes[mapTheme].styles,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        streetViewControl: false,
        zoomControlOptions: { position: window.google.maps.ControlPosition.RIGHT_TOP },
        minZoom: DEFAULT_MAP_ZOOM_LEVEL_MIN,
        maxZoom: DEFAULT_MAP_ZOOM_LEVEL_MAX,
        controlSize: 32,
      });

      markerClusterersRef.current = agentStatuses.map(
        (status) =>
          new MarkerClusterer({
            map: nextMap,
            markers: [],
            renderer: { render: getMarkerClusterRender(status) },
            algorithm: new SuperClusterAlgorithm({ maxZoom: CARRIER_MAP_ZOOM_LEVEL - 1 }),
          }),
      );
      if (showMapTrafficLayer) {
        trafficLayerRef.current.setMap(nextMap);
      }
      setMap(nextMap);
    }, [map, mapTheme, showMapTrafficLayer]);

    useDeepCompareEffectForMaps(() => {
      map?.setOptions({
        zoom: initialZoom,
        center: { lat: initialLat, lng: initialLng },
      });
    }, [map]);

    useDeepCompareEffectForMaps(() => {
      if (followingZoom) {
        map?.setOptions({
          zoom: followingZoom,
        });
      }
      if (followingLat && followingLng) {
        map?.setOptions({
          center: { lat: followingLat, lng: followingLng },
        });
      }
    }, [map, followingZoom, followingLat, followingLng]);

    useEffect(
      () => () => {
        markerClusterersRef.current.forEach((clusterer) => {
          clusterer.clearMarkers();
          clusterer.setMap(null);
        });
        markersRef.current.forEach(({ markerElement }) => {
          google.maps.event.clearInstanceListeners(markerElement);
          markerElement.setMap(null);
        });
        markerClusterersRef.current = [];
        markersRef.current = [];
      },
      [],
    );

    useEffect(() => {
      map?.setMapTypeId(mapThemes[mapTheme].type);
      map?.setOptions({ styles: mapThemes[mapTheme].styles });
    }, [map, mapTheme]);

    useEffect(() => {
      const currentMarkerIds = markersRef.current.map(({ data }) => data.id);
      const newMarkerIds = markers.map(({ id }) => id);

      const addedMarkerIds = difference(newMarkerIds, currentMarkerIds);
      const removedMarkerIds = difference(currentMarkerIds, newMarkerIds);
      const updatedMarkerIds = newMarkerIds.filter((id) => currentMarkerIds.includes(id));

      // Remove markers
      removedMarkerIds.forEach((makerIdToRemove) => {
        const marker = markersRef.current.find(({ data }) => data.id === makerIdToRemove);
        if (marker) {
          const statusIndex = agentStatuses.findIndex((status) => status === marker?.data.status);
          markerClusterersRef.current[statusIndex]?.removeMarker(marker.markerElement);
          google.maps.event.clearInstanceListeners(marker.markerElement);
          marker.markerElement.setMap(null);
        }
        markersRef.current = markersRef.current.filter(({ data }) => data.id !== makerIdToRemove);
      });

      // Add/update markers
      markers.forEach((markerData) => {
        const commonOptions: google.maps.MarkerOptions = {
          map,
          draggable: false,
          anchorPoint: computeAnchorPoint(
            markerData.type,
            markerData.status,
            markerData.isHighlighted,
          ),
          icon: computeIcon(markerData.type, markerData.status, markerData.isHighlighted),
          label: {
            text: markerData.label,
            color: 'white',
            fontWeight: '600',
          },
          position: markerData.position,
          zIndex: markerData.zIndex,
        };
        if (addedMarkerIds.includes(markerData.id)) {
          // Add markers
          const markerElement: google.maps.Marker = new google.maps.Marker(commonOptions);
          if (markerData.onClick) {
            markerElement.addListener('click', () => markerData.onClick?.(markerData.id));
          }
          markerElement.addListener('mouseover', () => {
            infoWindowRef.current.setContent(markerData.tooltip);
            infoWindowRef.current.open({ map, anchor: markerElement, shouldFocus: false });
          });
          markerElement.addListener('mouseout', () => {
            infoWindowRef.current.close();
          });
          const statusIndex = agentStatuses.findIndex((status) => status === markerData.status);
          markerClusterersRef.current[statusIndex]?.addMarker(markerElement);
          markersRef.current.push({ data: markerData, markerElement });
        } else if (updatedMarkerIds.includes(markerData.id)) {
          // Update markers
          const markerElement = markersRef.current.find(
            ({ data }) => data.id === markerData.id,
          )?.markerElement;
          if (markerElement) {
            markerElement.setIcon(commonOptions.icon);
            markerElement.setLabel(commonOptions.label);
            markerElement.setPosition(commonOptions.position);
            markerElement.setZIndex(commonOptions.zIndex);
            markerElement.addListener('mouseover', () => {
              infoWindowRef.current.setContent(markerData.tooltip);
              infoWindowRef.current.open({ map, anchor: markerElement, shouldFocus: false });
            });
            markerElement.addListener('mouseout', () => {
              infoWindowRef.current.close();
            });
            markersRef.current = markersRef.current.map((item) =>
              item.data.id === markerData.id ? { data: markerData, markerElement } : item,
            );
          }
        }
      });
    }, [map, markers]);

    const handleRecenterMap = useCallback(() => {
      mapFitBounds({
        map,
        points: markers.map((marker) => ({ lat: marker.position.lat, lng: marker.position.lng })),
      });
    }, [map, markers]);

    const handleToggleTrafficLayer = useCallback(() => {
      if (map && !trafficLayerRef.current.getMap()) {
        trafficLayerRef.current.setMap(map);
        setShowMapTrafficLayer(true);
      } else {
        trafficLayerRef.current.setMap(null);
        setShowMapTrafficLayer(false);
      }
    }, [map, setShowMapTrafficLayer]);

    const mapThemeMenu: MenuProps = useMemo(
      () => ({
        activeKey: mapTheme,
        items: mapThemeKeys.map((themeKey) => ({
          key: themeKey,
          label: i18n.t(`map.styles.${themeKey}`),
          onClick: () => setMapTheme(themeKey),
          disabled: themeKey === mapTheme,
        })),
      }),
      [mapTheme, setMapTheme],
    );

    const CarIcon = useMemo(
      () => (showMapTrafficLayer ? CarFilled : CarOutlined),
      [showMapTrafficLayer],
    );

    const mapAndMaybeControlButtons = (
      <>
        <MapDiv ref={mapDivRef} data-id="map" />
        {!isBlurred && (
          <ButtonsDiv>
            <Tooltip placement="left" title={i18n.t('map.recenter')}>
              <TeamOutlined onClick={handleRecenterMap} data-id="recenter-map-btn" />
            </Tooltip>
            <Tooltip
              placement="left"
              title={
                showMapTrafficLayer ? i18n.t('map.liveTrafficHide') : i18n.t('map.liveTrafficShow')
              }
            >
              <CarIcon
                onClick={handleToggleTrafficLayer}
                data-id="live-traffic-map-btn"
                data-active={showMapTrafficLayer}
              />
            </Tooltip>
            <Tooltip placement="left" title={i18n.t('map.theme')}>
              <Dropdown
                trigger={['click']}
                menu={mapThemeMenu}
                placement="bottomRight"
                arrow
                data-id="select-map-theme-map-btn"
              >
                <FormatPainterOutlined />
              </Dropdown>
            </Tooltip>
          </ButtonsDiv>
        )}
      </>
    );

    return (
      <>
        {isLoading && (
          <LoadingDiv data-id="map-loading">
            <div>
              <span>{i18n.t('general.common.loading')}</span>
              <Spin size="large" />
            </div>
          </LoadingDiv>
        )}
        {isBlurred ? (
          <BlurredDiv>{mapAndMaybeControlButtons}</BlurredDiv>
        ) : (
          mapAndMaybeControlButtons
        )}
      </>
    );
  },
);

MainMapElement.displayName = 'MainMapElement';

export default MainMapElement;
