export enum EQUIPMENT_STATUS {
  no_error = 'no_error',
  sensor_unpaired = 'sensor_unpaired',
  phone_ble_disabled = 'phone_ble_disabled',
  ble_disabled = 'ble_disabled',
  sensor_disconnected = 'sensor_disconnected',
  sensor_connecting = 'sensor_connecting',
  no_sensor = 'no_sensor',
  sensor_error = 'sensor_error',
  sensor_inactive = 'sensor_inactive',
  sensor_data_error = 'sensor_data_error',
  sensor_broken = 'sensor_broken',
}

export interface EquipmentStatusDetails {
  status: EQUIPMENT_STATUS;
  /** @deprecated This is a legacy field computed by the frontend and will eventually be removed */
  healthy: boolean;
}

export interface EquipmentStatusMap {
  connectionLost: EquipmentStatusDetails;
  offline: EquipmentStatusDetails;
  emergencyButton: EquipmentStatusDetails;
  heartRate: EquipmentStatusDetails;
  physiologicalTemperature: EquipmentStatusDetails;
  /** @deprecated This is an old sensor and it will eventually be removed */
  bodyTemperature: EquipmentStatusDetails; // deprecated
  gas: EquipmentStatusDetails;
  traakFront: EquipmentStatusDetails;
  traakBack: EquipmentStatusDetails;
  battery: EquipmentStatusDetails;
  lteSignalStrength: EquipmentStatusDetails;
}
