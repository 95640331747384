import { createContext, useContext, useMemo, useCallback, useState, type ReactNode } from 'react';

import AboutModal from '~/components/modals/AboutModal';
import AgentAddEditModal, {
  type AgentAddEditModalProps,
} from '~/components/modals/AgentAddEditModal';
import AgentDebugModal, { type AgentDebugModalProps } from '~/components/modals/AgentDebugModal';
import AgentDeleteModal, { type AgentDeleteModalProps } from '~/components/modals/AgentDeleteModal';
import AlarmControlModal, {
  type AlarmControlModalProps,
} from '~/components/modals/AlarmControlModal';
import AlarmInformationModal, {
  type AlarmInformationModalProps,
} from '~/components/modals/AlarmInformationModal';
import ApiDocumentationModal from '~/components/modals/ApiDocumentationModal';
import BackOfficeCreateEditCompanyModal, {
  BackOfficeCreateEditCompanyModalProps,
} from '~/components/modals/BackOfficeCreateEditCompanyModal';
import ConfirmationModal, {
  type ConfirmationModalProps,
} from '~/components/modals/ConfirmationModal';
import HighlightedFeaturesModal from '~/components/modals/HighlightedFeaturesModal';
import LocationHistoryModal, {
  type LocationHistoryModalProps,
} from '~/components/modals/LocationHistoryModal';
import VideoRecordingsModal, {
  type VideoRecordingsModalProps,
} from '~/components/modals/VideoRecordingsModal';
import WelcomeBackModal from '~/components/modals/WelcomeBackModal';

type OpenModalParams =
  // Generic
  | ({
      type: 'confirmation';
    } & ConfirmationModalProps)
  // Specific
  | {
      type: 'welcomeBack';
    }
  | {
      type: 'about';
    }
  | {
      type: 'apiDocumentation';
    }
  | {
      type: 'highlightedFeatures';
    }
  | {
      type: 'agentAdd';
    }
  | ({
      type: 'agentEdit';
    } & AgentAddEditModalProps)
  | ({
      type: 'agentDebug';
    } & AgentDebugModalProps)
  | ({
      type: 'agentDelete';
    } & AgentDeleteModalProps)
  | ({
      type: 'alarmControl';
    } & AlarmControlModalProps)
  | ({
      type: 'alarmInformation';
    } & AlarmInformationModalProps)
  | ({
      type: 'locationHistory';
    } & LocationHistoryModalProps)
  | ({
      type: 'videoRecordings';
    } & VideoRecordingsModalProps)
  // Backoffice
  | ({
      type: 'backOfficeCreateEditCompany';
    } & BackOfficeCreateEditCompanyModalProps);

interface ModalsContextType {
  openModal: (params: OpenModalParams) => void;
  closeModals: () => void;
}

const ModalsContext = createContext<ModalsContextType>({
  openModal: () => undefined,
  closeModals: () => undefined,
});

ModalsContext.displayName = 'ModalsContext';

export function ModalsContextProvider({ children }: { children: ReactNode }) {
  const [activeModal, setActiveModal] = useState<OpenModalParams | null>(null);

  const openModal = useCallback(
    (params: OpenModalParams) => {
      setActiveModal(params);
    },
    [setActiveModal],
  );

  const closeModals = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  const maybeModal = useMemo(
    () =>
      (() => {
        switch (activeModal?.type) {
          // Generic
          case 'confirmation':
            return (
              <ConfirmationModal
                isOpen
                onClose={closeModals}
                title={activeModal?.title}
                description={activeModal?.description}
                actionType={activeModal?.actionType}
                action={activeModal?.action}
              />
            );
          // Specific
          case 'welcomeBack':
            return <WelcomeBackModal isOpen onClose={closeModals} />;
          case 'about':
            return <AboutModal isOpen onClose={closeModals} />;
          case 'apiDocumentation':
            return <ApiDocumentationModal isOpen onClose={closeModals} />;
          case 'highlightedFeatures':
            return <HighlightedFeaturesModal isOpen onClose={closeModals} />;
          case 'agentAdd':
            return <AgentAddEditModal isOpen onClose={closeModals} agentId={undefined} />;
          case 'agentEdit':
            return (
              <AgentAddEditModal isOpen onClose={closeModals} agentId={activeModal?.agentId} />
            );
          case 'agentDebug':
            return <AgentDebugModal isOpen onClose={closeModals} agentId={activeModal?.agentId} />;
          case 'agentDelete':
            return <AgentDeleteModal isOpen onClose={closeModals} agentId={activeModal?.agentId} />;
          case 'alarmControl':
            return (
              <AlarmControlModal
                isOpen
                onClose={closeModals}
                agentId={activeModal?.agentId}
                alarmId={activeModal?.alarmId}
              />
            );
          case 'alarmInformation':
            return (
              <AlarmInformationModal
                isOpen
                onClose={closeModals}
                alarmId={activeModal?.alarmId}
                openAlarmControlModal={activeModal?.openAlarmControlModal}
              />
            );
          case 'locationHistory':
            return (
              <LocationHistoryModal isOpen onClose={closeModals} agentId={activeModal?.agentId} />
            );
          case 'videoRecordings':
            return (
              <VideoRecordingsModal
                isOpen
                onClose={closeModals}
                defaultAgentId={activeModal?.defaultAgentId}
                initialDateRange={activeModal?.initialDateRange}
              />
            );
          // Backoffice
          case 'backOfficeCreateEditCompany':
            return (
              <BackOfficeCreateEditCompanyModal
                isOpen
                onClose={closeModals}
                companyId={activeModal?.companyId}
              />
            );
          default:
            return null;
        }
      })(),
    [activeModal, closeModals],
  );

  const value: ModalsContextType = useMemo(
    () => ({ openModal, closeModals }),
    [openModal, closeModals],
  );

  return (
    <ModalsContext.Provider value={value}>
      {maybeModal}
      {children}
    </ModalsContext.Provider>
  );
}

export default function useModalsContext() {
  return useContext(ModalsContext);
}
