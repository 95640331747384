import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined';
import { Alert, Table, Tooltip, Button, type TableColumnsType } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useMemo, type Key, type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useMutationDeleteCompanyBackOffice from '~/apollo/hooks/backoffice/useMutationDeleteCompanyBackOffice';
import useQueryCompanyListBackOffice, {
  type CompanyListBackOfficeQueryData,
} from '~/apollo/hooks/backoffice/useQueryCompanyListBackOffice';
import TimeAgo from '~/components/TimeAgo';
import routes from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';

import TabSectionHeader from '../../../../components/SettingsTabs/components/TabSectionHeader';

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const TopFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const TableListUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface TableDataType {
  key: Key;
  name: string;
  id: string;
  companyAdmins: CompanyListBackOfficeQueryData['getAllCompanies']['items'][0]['admins']['items'];
  companyAdminsCount: number;
  subsidiaries: CompanyListBackOfficeQueryData['getAllCompanies']['items'][0]['subsidiaries']['items'];
  subsidiariesCount: number;
  createdAt: string;
  actions: ReactNode;
}

const columns: TableColumnsType<TableDataType> = [
  {
    dataIndex: 'name',
    title: 'Company name',
    sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    render: (text, record) => (
      <Tooltip
        title={
          <>
            <b>ID:</b> {record.id}
          </>
        }
        placement="topLeft"
      >
        <span style={{ cursor: 'help' }}>
          <b>{text}</b>
        </span>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'subsidiariesCount',
    title: 'Company subsidiaries',
    sorter: (a, b) => a.subsidiariesCount - b.subsidiariesCount,
    render: (text, record) => {
      const sortedSubsidiaries = orderBy(
        record.subsidiaries,
        [(item) => item.name.toLowerCase()],
        ['asc'],
      );

      return (
        <span>
          <b>Subsidiaries: {text}</b>
          <TableListUl>
            {sortedSubsidiaries.map((subsidiary) => (
              <li key={subsidiary.id}>{subsidiary.name || subsidiary.id}</li>
            ))}
          </TableListUl>
        </span>
      );
    },
  },
  {
    dataIndex: 'companyAdminsCount',
    title: 'Company admins',
    sorter: (a, b) => a.companyAdminsCount - b.companyAdminsCount,
    render: (text, record) => {
      const sortedAdmins = orderBy(
        record.companyAdmins,
        [(item) => item.email.toLowerCase()],
        ['asc'],
      );

      return (
        <span>
          <b>Admins: {text}</b>
          <TableListUl>
            {sortedAdmins.map((admin) => (
              <li key={admin.id}>{admin.email || admin.id}</li>
            ))}
          </TableListUl>
        </span>
      );
    },
  },
  {
    dataIndex: 'createdAt',
    title: 'Created at',
    sorter: (a, b) => a.createdAt.toLowerCase().localeCompare(b.createdAt.toLowerCase()),
    defaultSortOrder: 'descend',
    render: (text) => (
      <Tooltip title={formatDateTime(text)}>
        <span style={{ cursor: 'help' }}>
          <TimeAgo date={text} />
        </span>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'actions',
    title: 'Actions',
  },
];

const TabCompanies = memo(() => {
  const { isSuperAdmin, isLoading: isLoadingCurrentUser } = useCurrentUserContext();
  const { currentCompany, setCurrentCompanyId } = useCompany();
  const { openModal } = useModalsContext();
  const {
    companyList,
    isLoading: isLoadingCompanyList,
    refetchCompanyList,
  } = useQueryCompanyListBackOffice();
  const { deleteCompany, isLoading: isLoadingDeleteCompany } = useMutationDeleteCompanyBackOffice();

  const isLoading = isLoadingCurrentUser || isLoadingCompanyList || isLoadingDeleteCompany;

  const navigate = useNavigate();

  const data: TableDataType[] = useMemo(
    () =>
      companyList.map((company) => {
        const canBeDeleted =
          company.subsidiaries.items.length === 0 && company.admins.items.length === 0;

        return {
          key: company.id,
          name: company.name,
          id: company.id,
          companyAdmins: company.admins.items,
          companyAdminsCount: company.admins.items.length,
          subsidiaries: company.subsidiaries.items,
          subsidiariesCount: company.subsidiaries.items.length,
          createdAt: company.created_at,
          actions: (
            <ActionsDiv>
              <Tooltip title="Switch to company">
                <Button
                  onClick={() => {
                    setCurrentCompanyId(company.id);
                    navigate(
                      routes.backoffice({
                        tab: 'companyFeatures',
                      }),
                    );
                  }}
                  icon={<UserSwitchOutlined />}
                  disabled={currentCompany?.id === company.id}
                />
              </Tooltip>
              <Tooltip title="Edit company">
                <Button
                  onClick={() => {
                    openModal({
                      type: 'backOfficeCreateEditCompany',
                      companyId: company.id,
                    });
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Tooltip
                title={
                  canBeDeleted
                    ? 'Delete company'
                    : 'Cannot delete company with subsidiaires or admins'
                }
              >
                <Button
                  disabled={!canBeDeleted}
                  onClick={() => {
                    openModal({
                      type: 'confirmation',
                      title: 'Delete company',
                      description: (
                        <div>
                          Are you sure you want to delete the company <b>{company.name}</b>?
                        </div>
                      ),
                      actionType: 'danger',
                      action: async () => {
                        try {
                          await deleteCompany({
                            variables: {
                              company_id: company.id,
                            },
                          });
                          await refetchCompanyList();
                          notification.success({
                            message: 'Success',
                            description: 'Company deleted successfully',
                          });
                        } catch (error) {
                          notification.error({
                            message: (error as any)?.message || 'Error',
                          });
                        }
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </ActionsDiv>
          ),
        };
      }),
    [
      companyList,
      currentCompany?.id,
      deleteCompany,
      navigate,
      openModal,
      refetchCompanyList,
      setCurrentCompanyId,
    ],
  );

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <WrapperDiv>
      <Alert
        style={{ marginTop: '16px' }}
        message={
          <>
            Here <b>super admins</b> view all existing companies and create new ones.
          </>
        }
        type="info"
        showIcon
      />
      <TopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('general.common.loading')
              : `List of companies (${companyList.length})`
          }
        />
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            openModal({
              type: 'backOfficeCreateEditCompany',
              companyId: undefined,
            });
          }}
        >
          Create company
        </Button>
      </TopFlexDiv>
      <StyledTable
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: false,
          pageSizeOptions: undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} companies`,
          hideOnSinglePage: true,
        }}
      />
    </WrapperDiv>
  );
});

TabCompanies.displayName = 'TabCompanies';

export default TabCompanies;
