import AimOutlined from '@ant-design/icons/AimOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import { Button, Tooltip } from 'antd';
import camelCase from 'lodash/camelCase';
import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import SortableSearchableTable from '~/components/SortableSearchableTable';
import { agentsTableId } from '~/config/elementIds';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useInitialLoading from '~/hooks/useInitialLoading';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS } from '~/types/agent';
import getAgentNameWithAcronym from '~/utils/agent/getAgentNameWithAcronym';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import getAgentStatusTranslation from '~/utils/agent/getAgentStatusTranslation';
import { computeMissionTime } from '~/utils/dateTime';
import getEquipmentStatusLabel from '~/utils/equipment/getEquipmentStatusLabel';

import useAgentsTableColumns from './hooks/useAgentsTableColumns';
import computeEquipmentStatuses from './utils/computeEquipmentStatuses';

const ActionDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const PopoverSummaryDl = styled.dl`
  margin: 0;

  & > div {
    display: flex;
    gap: 4px;

    dt {
      margin: 0;
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }
`;

const AgentsTable = memo(() => {
  const { isInitialLoading } = useInitialLoading();
  const { agents } = useAgentsContext();
  const { openModal } = useModalsContext();
  const columns = useAgentsTableColumns();
  const { companyFeatures, showLocationHistoryFeature } = useCompanyFeatures();
  const navigate = useNavigate();
  const { isSuperAdmin } = useCurrentUserContext();

  const dataSource = useMemo(
    () =>
      agents.map((agent) => {
        const garmentStatuses = computeEquipmentStatuses(agent.equipmentStatus, companyFeatures);
        const statusColor = getAgentStatusColor(agent);

        const garmentStatusPopoverItems: {
          title: string;
          color: string;
          label: string;
          description: string;
        }[] = [
          {
            title: i18n.t('carrierDetailsPopup.equipment.brain'),
            color: garmentStatuses.brain.color,
            label: garmentStatuses.brain.label,
            description: '',
          },
          ...(companyFeatures.emergencyButton
            ? [
                {
                  title: i18n.t('carrierDetailsPopup.equipment.sosExternalButton'),
                  color: garmentStatuses.emergencyButton.color,
                  label: garmentStatuses.emergencyButton.label,
                  description: getEquipmentStatusLabel(agent.equipmentStatus.emergencyButton),
                },
              ]
            : []),
          ...(companyFeatures.heartRateSensor
            ? [
                {
                  title: i18n.t('carrierDetailsPopup.equipment.heartRate'),
                  color: garmentStatuses.heartRate.color,
                  label: garmentStatuses.heartRate.label,
                  description: getEquipmentStatusLabel(agent.equipmentStatus.heartRate),
                },
              ]
            : []),
          ...(companyFeatures.physiologicalTemperatureSensor
            ? [
                {
                  title: i18n.t('carrierDetailsPopup.equipment.physiologicalTemperature'),
                  color: garmentStatuses.physiologicalTemperature.color,
                  label: garmentStatuses.physiologicalTemperature.label,
                  description: getEquipmentStatusLabel(
                    agent.equipmentStatus.physiologicalTemperature,
                  ),
                },
              ]
            : []),
          ...(companyFeatures.bodyTemperatureSensor
            ? [
                {
                  title: i18n.t('carrierDetailsPopup.equipment.bodyTemperature'),
                  color: garmentStatuses.bodyTemperature.color,
                  label: garmentStatuses.bodyTemperature.label,
                  description: getEquipmentStatusLabel(agent.equipmentStatus.bodyTemperature),
                },
              ]
            : []),
          ...(companyFeatures.gasSensor
            ? [
                {
                  title: i18n.t('carrierDetailsPopup.equipment.gas'),
                  color: garmentStatuses.gas.color,
                  label: garmentStatuses.gas.label,
                  description: getEquipmentStatusLabel(agent.equipmentStatus.gas),
                },
              ]
            : []),
          ...(companyFeatures.impactDetectionFront
            ? [
                {
                  title: i18n.t('carrierDetailsPopup.equipment.traakFront'),
                  color: garmentStatuses.traakFront.color,
                  label: garmentStatuses.traakFront.label,
                  description: getEquipmentStatusLabel(agent.equipmentStatus.traakFront),
                },
              ]
            : []),
          ...(companyFeatures.impactDetectionBack
            ? [
                {
                  title: i18n.t('carrierDetailsPopup.equipment.traakBack'),
                  color: garmentStatuses.traakBack.color,
                  label: garmentStatuses.traakBack.label,
                  description: getEquipmentStatusLabel(agent.equipmentStatus.traakBack),
                },
              ]
            : []),
        ];

        return {
          key: agent.id,
          agent: getAgentNameWithAcronym(agent) || '-',
          agentPopover: (
            <PopoverSummaryDl data-id="agent-attributes">
              {(['first_name', 'last_name', 'phone_number', 'team'] as const).map(
                (attributeName) => (
                  <div key={attributeName}>
                    <dt>{i18n.t(`agentForm.${camelCase(attributeName)}`)}:</dt>
                    <dd>{agent.attributes[attributeName] || i18n.t('agentForm.unknown')}</dd>
                  </div>
                ),
              )}
            </PopoverSummaryDl>
          ),
          team: agent.team || '-',
          vehicle: (!agent.isOffline && agent.attributes.plate_number) || '-',
          status: agent.status,
          agentStatus: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span
                style={{
                  display: 'inline-block',
                  borderRadius: '3px',
                  color:
                    agent.isOffline && agent.status !== AGENT_STATUS.alert
                      ? theme.colors.darkGrey
                      : theme.colors.white,
                  fontSize: '15px',
                  backgroundColor: statusColor,
                  padding: '5px 10px',
                }}
              >
                {getAgentStatusTranslation({ isOffline: agent.isOffline, status: agent.status })}
              </span>
            </div>
          ),
          agentStatusText: getAgentStatusTranslation({
            isOffline: agent.isOffline,
            status: agent.status,
          }),
          isOffline: agent.isOffline,
          missionStart: computeMissionTime(agent.missionStartTimeISO) || '-',
          missionEnd: computeMissionTime(agent.missionEndTimeISO) || '-',
          garment: agent.deviceName || '-',
          garmentStatus: garmentStatuses.status.label,
          garmentStatusColor: garmentStatuses.status.color,
          garmentStatusPopover: agent.isOffline ? null : (
            <PopoverSummaryDl data-id="agent-garment-details">
              {garmentStatusPopoverItems.map((item) => (
                <div key={item.title}>
                  <dt>{item.title}:</dt>
                  <dd style={{ color: item.color }}>
                    {item.label}
                    {item.description ? ` (${item.description})` : ''}
                  </dd>
                </div>
              ))}
            </PopoverSummaryDl>
          ),
          actions: (
            <ActionDiv>
              {isSuperAdmin && (
                <Tooltip title={i18n.t('agentDebugModal.buttonTooltip')} placement="left">
                  <Button
                    onClick={() => {
                      if (agent?.id) {
                        openModal({
                          type: 'agentDebug',
                          agentId: agent.id,
                        });
                      }
                    }}
                    data-id="debug-modal-btn"
                    icon={<ProfileOutlined />}
                  />
                </Tooltip>
              )}
              <Tooltip title={agent.isOffline ? undefined : i18n.t('agentsPage.viewDetails')}>
                <Button
                  disabled={agent.isOffline && agent.status !== AGENT_STATUS.alert}
                  onClick={() => {
                    navigate(
                      routes.status({
                        id: agent.id,
                      }),
                    );
                  }}
                  data-id="agent-details-btn"
                  icon={<InfoCircleOutlined />}
                />
              </Tooltip>
              {showLocationHistoryFeature && (
                <Tooltip title={i18n.t('agentsPage.locationHistory')}>
                  <Button
                    onClick={() => {
                      if (agent?.id) {
                        openModal({
                          type: 'locationHistory',
                          agentId: agent.id,
                        });
                      }
                    }}
                    data-id="location-history-btn"
                    icon={<AimOutlined />}
                  />
                </Tooltip>
              )}
              {companyFeatures.playbackRecordedVideos && (
                <Tooltip title={i18n.t('agentsPage.videoRecordings')}>
                  <Button
                    onClick={() => {
                      if (agent?.id) {
                        openModal({
                          type: 'videoRecordings',
                          defaultAgentId: agent.id,
                          initialDateRange: ['', ''],
                        });
                      }
                    }}
                    data-id="video-recordings-btn"
                    icon={<VideoCameraOutlined />}
                  />
                </Tooltip>
              )}
              <Tooltip title={i18n.t('agentForm.editAgent')}>
                <Button
                  onClick={() => {
                    openModal({ type: 'agentEdit', agentId: agent?.id });
                  }}
                  data-id="edit-agent-btn"
                  icon={<EditOutlined />}
                />
              </Tooltip>
              {companyFeatures.deleteAgents && (
                <Tooltip title={i18n.t('agentForm.deleteAgent')}>
                  <Button
                    danger
                    onClick={() => {
                      openModal({ type: 'agentDelete', agentId: agent?.id });
                    }}
                    data-id="delete-agent-btn"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              )}
            </ActionDiv>
          ),
        };
      }) ?? [],
    [agents, companyFeatures, isSuperAdmin, showLocationHistoryFeature, navigate, openModal],
  );

  return (
    <SortableSearchableTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      id={agentsTableId}
      data-id="agents-table"
      loading={isInitialLoading}
    />
  );
});

AgentsTable.displayName = 'AgentsTable';

export default AgentsTable;
