import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import PageSideTabs from '~/components/PageSideTabs';
import SettingsTabIntegrations from '~/components/SettingsTabs/SettingsTabIntegrations';
import SettingsTabProfile from '~/components/SettingsTabs/SettingsTabProfile';
import SettingsTabSecurity from '~/components/SettingsTabs/SettingsTabSecurity';
import SettingsTabSubsidiaryDetails from '~/components/SettingsTabs/SettingsTabSubsidiaryDetails';
import routes from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 300px) minmax(0, 1fr);
  grid-gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const StickyDiv = styled.div`
  position: sticky;
  top: calc(${theme.dimensions.navbarHeight}px + 16px);

  ${theme.medias.lteSmall} {
    position: initial;
    top: 0;
  }
`;

const TabTitleH3 = styled.h3`
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 8px;
`;

const TabContentDiv = styled.div`
  padding-bottom: 64px;
`;

enum TabEnum {
  profile = 'profile',
  security = 'security',
  integrations = 'integrations',
  subsidiaryDetails = 'subsidiaryDetails',
}

const SettingsPage = memo(() => {
  const { currentCompany } = useCompany();
  const { isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [tab, setTab] = useState<TabEnum>((queryParams.tab as TabEnum) || TabEnum.profile);

  const isCompanyAdminOrSuperAdmin = currentCompany?.id && (isSuperAdmin || isCompanyAdmin);

  const tabs = useMemo(
    () => [
      { key: TabEnum.profile, icon: <UserOutlined /> },
      { key: TabEnum.security, icon: <LockOutlined /> },
      ...(isCompanyAdminOrSuperAdmin
        ? [{ key: TabEnum.integrations, icon: <ApartmentOutlined /> }]
        : []),
      { key: TabEnum.subsidiaryDetails, icon: <IdcardOutlined /> },
    ],
    [isCompanyAdminOrSuperAdmin],
  );

  useEffect(() => {
    if (queryParams.tab) {
      setTab(queryParams.tab as TabEnum);
    }
  }, [queryParams.tab]);

  const handleTabSelect = useCallback(
    (selectedTab: TabEnum) => {
      setTab(selectedTab);
      navigate(routes.settings({ ...queryParams, tab: selectedTab }));
    },
    [navigate, queryParams],
  );

  return (
    <PageContentWrapper>
      <Container>
        <PageHeader
          title={i18n.t('settingsPage.title')}
          subtitle={i18n.t('settingsPage.subtitle')}
        />
        <GridDiv>
          <aside>
            <StickyDiv>
              <PageSideTabs
                activeItemKey={tab}
                items={tabs.map(({ key, icon }) => ({
                  key,
                  dataId: `settings-${key}-link`,
                  icon,
                  label: i18n.t(`settingsPage.tabs.${key}`),
                  onClick: () => handleTabSelect(key),
                }))}
              />
            </StickyDiv>
          </aside>
          <TabContentDiv>
            <TabTitleH3 data-id="settings-page-tab-title">
              {i18n.t(`settingsPage.tabs.${tab}`)}
            </TabTitleH3>
            {tab === TabEnum.profile && <SettingsTabProfile />}
            {tab === TabEnum.security && <SettingsTabSecurity />}
            {tab === TabEnum.integrations && <SettingsTabIntegrations />}
            {tab === TabEnum.subsidiaryDetails && <SettingsTabSubsidiaryDetails />}
          </TabContentDiv>
        </GridDiv>
      </Container>
    </PageContentWrapper>
  );
});

SettingsPage.displayName = 'SettingsPage';

export default SettingsPage;
